import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBlock from "../components/ContentBlock"

const PageTermsOfService = () => (
  <Layout>
    <PageTitle title="Terms of Service"></PageTitle>
    <ContentBlock className="rich-text">
      <p>
        Welcome to Towbook! This{" "}
        <strong>
          Terms of Service and End User License Agreement (“Agreement”)
        </strong>{" "}
        constitutes a valid and binding agreement between Extric LLC, DBA
        Towbook (together with its affiliates, successors and assigns “Company,”
        “we,” or “us”) and you (“Client,” “you,” or “your”). This Agreement
        explains the terms by which you may use our online and/or mobile
        services, application, website, and software provided by or in
        connection with the service (collectively the “Services”).
      </p>
      <p>
        By accessing or using the Services, you signify that you have read,
        understood, and agree to be bound by this terms herein. If you do not
        agree, you may not use the Services. You may only access and use the
        Services in accordance with this Agreement. You will adhere to all laws,
        rules, and regulations applicable to your use of the Services.
      </p>
      <p>
        You represent to us that you are lawfully able to enter into contracts.
        You must be at least 18 years of age (or the age of legal majority in
        your jurisdiction if different than 18) to be eligible to use the
        Services. Any use or access to the Services by anyone under 13 is
        strictly prohibited and in violation of this Agreement. In certain
        instances, Company may require you to provide proof of identity to
        create your account, or to access or use the Services, and you
        acknowledge and agree that you may be denied access or use of the
        Services if you refuse to provide such proof. This Agreement applies to
        all visitors, users, and others who access the Services (“
        <strong>Users</strong>”).{" "}
      </p>
      <h3>1. Use of Our Services</h3>
      <ol className="spaced list-lower-alpha">
        <li>
          This Agreement governs your access and use of the Services, and any
          information that is displayed or provided therein. The Services
          provide a wide range of administrative and management tools for the
          towing industry. By accessing and/or using the Services, you are
          indicating your acceptance of this Agreement, which thereby becomes a
          binding contract between you and Company, and you agree to be bound by
          all terms and conditions herein. Company’s acceptance is expressly
          conditioned upon your assent to all the terms and conditions of this
          Agreement, to the exclusion of all other terms.
        </li>
        <li>
          <strong>License</strong>
          <ol className="spaced list-lower-roman">
            <li>
              Subject to the terms and conditions of this Agreement, you are
              hereby granted a non-exclusive, limited, non-transferable, freely
              revocable license to use the Services as permitted by the features
              of the Services. Company reserves all rights not expressly granted
              herein in the Services and the Company Content (as defined below).
              Company may terminate this license at any time for any reason or
              no reason.{" "}
            </li>
            <li>
              You acknowledge and agree that the Services are licensed solely
              for your own business or personal use and you may not use the
              Services for any other purposes, without prior written
              authorization from Company. You agree to not grant access to any
              third party for any purpose whatsoever without the prior written
              consent of Company; (b) make the Services, in whole or in part,
              available to any other person, entity or business; (c) sell,
              sublicense, lease, permit, transfer, copy, reverse engineer,
              decompile or disassemble the Services, in whole or in part, or
              otherwise attempt to discover the source code to the software used
              in the Services; (d) remove, circumvent, disable, damage or
              otherwise interfere with security-related features of the
              Services, features that prevent or restrict use or copying of any
              content accessible through the Services, or features that enforce
              limitations on use of the Services; (e) remove any proprietary
              notices from the software of elsewhere on the Services; or (d)
              modify, alter, integrate, combine the Services or associated
              software with any other software or services not provided or
              approved by us. You have and will obtain no rights to the Services
              except for the limited rights to use the Services expressly
              granted by this Agreement. Any attempt by you to transfer any of
              the rights, duties or obligations hereunder, except as expressly
              provided for in this Agreement, is void. Company reserves all
              rights not expressly granted under this Agreement{" "}
            </li>
            <li>
              You acknowledge that Company may from time to time issue upgraded
              versions of the Services, and may automatically electronically
              upgrade the version of the Services that you are using. You
              consent to such automatic upgrading, and agree that the terms and
              conditions of this Agreement will apply to all such upgrades.{" "}
            </li>
          </ol>
        </li>
        <li>
          <strong>Accounts</strong>
          <ol className="spaced list-lower-roman">
            <li>
              In order to use the Services, you will be required to create an
              account and provide accurate, current and complete information in
              connection with your use of the Services (“Account“). You agree to
              maintain and promptly update your Account information as necessary
              to maintain its accuracy. Company reserves the right to suspend or
              terminate access to and use of the Services, or any portion
              thereof, on the basis of inaccurate or incomplete Account
              information.{" "}
            </li>
            <li>
              Your Account gives you access to the services and functionality
              that we may establish and maintain from time to time and in our
              sole discretion. We may maintain different types of accounts for
              different types of Users. If you open a Company Account on behalf
              of a company, organization, or other entity, then (a) “you”
              includes you and that entity, and (b) you represent and warrant
              that you are an authorized representative of the entity with the
              authority to bind the entity to this Agreement, and that you agree
              to this Agreement on the entity’s behalf.
            </li>
            <li>
              You are solely responsible for all activity that occurs when the
              Services are accessed through your Account, and you must keep your
              account password secure. You must notify Company immediately of
              any breach of security or unauthorized use of your account.
              Company will not be liable for any losses or damages arising from
              your failure to protect your password or Account information
              and/or caused by any unauthorized use of your Account.
            </li>
            <li>
              You may control your Account and how you interact with the
              Services by changing the settings in your Account. By setting up
              an Account, you consent to our using your email address to send
              you Service-related notices, including any notices required by
              law, in lieu of communication by postal mail. We may also use your
              email address to send you other messages, such as changes to
              features of the Services and special offers. If you do not want to
              receive such email messages, you may opt out or change your
              preferences in your Account settings page. Opting out may prevent
              you from receiving email messages regarding updates, improvements,
              or offers.
            </li>
            <li>
              By logging into the Company Services, using the Services, and/or
              accessing the information contained therein, you understand and
              agree that you absolutely may not share your log-in ID or password
              or any of the information contained within the Company Services
              with any third party whatsoever without the explicit written
              permission of Company. For the purpose of clarity, this includes
              providing access to, or allowing, third parties to log-in through
              your account, as well as copying and sharing reports and/or
              contact&apos;s emails, names or phone numbers, with any other
              individual, business, marketing or survey company.
            </li>
            <li>
              Any unauthorized access or use of the Company Services or the
              information contained therein, is a violation of Company&apos;s
              policies, may result in our suspending or terminating your
              account, and may subject you to criminal liability.
            </li>
          </ol>
        </li>
        <li>
          <strong>Service Rules</strong>
          <ol className="spaced list-lower-roman">
            <li>
              You agree not to engage in any of the following prohibited
              activities: (i) copying, distributing, or disclosing any part of
              the Services in any medium, including without limitation by any
              automated or non-automated “scraping”; (ii) using any automated
              system, including without limitation “robots,” “spiders,” “offline
              readers,” etc., to access the Services; (iii) transmitting spam,
              chain letters, or other unsolicited email; (iv) attempting to
              interfere with, compromise the system integrity or security or
              decipher any transmissions to or from the servers running the
              Services; (v) taking any action that imposes, or may impose at our
              sole discretion an unreasonable or disproportionately large load
              on our infrastructure; (vi) uploading invalid data, viruses,
              worms, or other software agents through the Services; (vii)
              collecting or harvesting any personally identifiable information,
              including account names, from the Services; (viii) using the
              Services for any commercial solicitation purposes; (ix)
              impersonating another person or otherwise misrepresenting your
              affiliation with a person or entity, stealing or assuming and
              person’s identity (whether a real identity or nickname or alias),
              conducting fraud, hiding or attempting to hide your identity; (x)
              interfering with the proper working of the Services; (xi)
              accessing any content on the Services through any technology or
              means other than those provided or authorized by the Services; or
              (xii) bypassing the measures we may use to prevent or restrict
              access to the Services, including without limitation features that
              prevent or restrict use or copying of any content or enforce
              limitations on use of the Services or the content therein.
              Furthermore, you may not use the Services to develop, generate,
              transmit or store information that: (A) infringes any third
              party’s intellectual property or other proprietary right; (B) is
              defamatory, harmful, abusive, obscene or hateful; (C) in any way
              obstructs or otherwise interferes with the normal performance of
              another person’s use of the Services, (D) performs any unsolicited
              commercial communication not permitted by applicable law; and (E)
              is harassment or a violation of privacy or threatens other people
              or groups of people.{" "}
            </li>
            <li>
              The Services contain confidential and trade secret information
              owned or licensed by Company, and you agree to take reasonable
              steps at all times to protect and maintain the confidentiality of
              such information.{" "}
            </li>
            <li>
              We may, without prior notice, change the Services; stop providing
              the Services or features of the Services, to you or to Users
              generally; or create usage limits for the Services. We may
              permanently or temporarily terminate or suspend your access to the
              Services without notice and liability for any reason, including if
              in our sole determination you violate any provision of this
              Agreement, or for no reason. Upon termination for any reason or no
              reason, you continue to be bound by this Agreement.{" "}
            </li>
          </ol>
        </li>
        <li>
          <strong>Availability of Services.</strong> Company will use
          commercially reasonable efforts to make the Services available
          pursuant to this Agreement except for (a) planned downtime; (b)
          emergency downtime; and (c) any unavailability caused by circumstances
          beyond our reasonable control. Company reserves the right to modify
          the Services from time to time and makes no guarantees as to the
          continuous availability of the Services or of any specific feature(s)
          or functionality(ies) of the Services.{" "}
        </li>
        <li>
          <strong>No Agency or Endorsement.</strong> You expressly acknowledge
          and agree that all Users are independent individuals and/or entities
          and not Company’s contractors, agents or employees. We do not make any
          representations or warranties of any kind with respect to any User or
          services provided or requested by Users, nor shall Company be deemed
          to endorse any User. We are not liable for the acts, errors,
          omissions, representations, warranties, breaches or negligence of any
          User or services provided or requested by Users, or for any personal
          injuries, death, property damage, or other damages or expenses
          resulting therefrom. We are not a party to any transaction with
          another User, and we are in no way responsible for the performance or
          non-performance of any User thereunder or the quality or lack thereof
          of any services provided by a User.{" "}
        </li>
        <li>
          <strong>Neutral Platform.</strong> The Services are provided and
          function solely as a neutral platform and is to be used as a general
          resource. The Company does not decide, or have any vested interest in,
          which User, if any, that any other User selects for any particular
          dispatch. The Company is not involved in or a party to any actual
          transactions between Users or between Users and other third parties,
          even if you link to them through the Services. As a result, Company
          has no control over the quality, timeliness, and/or safety of the
          transactions between Users and is absolutely not responsible for the
          actions or inactions of a User. You understand and agree that any User
          to whom you provide your services is solely responsible for payment of
          every dispatch through the Service. You agree and understand that you
          will not receive payment from Company for any reason whatsoever,
          including if you do not receive payment from another User and
          regardless of whether you have provided services to such User. In the
          same manner, any User requesting services from another User is
          responsible and solely liable for making payment for such Services.
          Any dispute between a User and another User and/or such User’s
          customer is between the parties involved, and Company will not, in any
          event, be a party to that dispute. However, we want to try to help if
          there are issues, so if you are unable to resolve a dispute, the
          Company may use commercially reasonable efforts to mediate and arrive
          at a mutually agreed upon resolution.{" "}
        </li>
      </ol>

      <h3>2. Proprietary Rights</h3>
      <ol className="spaced list-lower-alpha">
        <li>
          The Services and all materials therein or transferred thereby,
          including, without limitation, software, images, text, graphics,
          illustrations, logos, patents, trademarks, service marks, copyrights,
          photographs, audio, videos, and music (the “Company Content”), and all
          intellectual property rights related thereto, are the exclusive
          property of Company and its licensors. Except as explicitly provided
          herein, nothing in this Agreement shall be deemed to create a license
          in or under any such intellectual property rights, and you agree not
          to sell, license, rent, modify, distribute, copy, reproduce, transmit,
          publicly display, publicly perform, publish, adapt, edit or create
          derivative works from any Company Content. Use of the Company Content
          for any purpose not expressly permitted by this Agreement is strictly
          prohibited. This Agreement does not provide you with title or
          ownership of any Services or Company Content, but only a limited right
          to use the same solely upon the terms expressly set forth in this
          Agreement. For the purpose of clarity, nothing provided under this
          Agreement is to be considered a “work for hire” and Company does not
          convey, transfer or assign to you any right, title and interest it may
          have now or in the future acquire, including but not limited to all
          intellectual property rights.{" "}
        </li>
        <li>
          Client materials. You will own and maintain ownership of all of your
          materials and data (“Client Materials”). We do not claim any ownership
          of the Client Materials that you submit, post, or display through the
          Services.
          <ol className="spaced list-lower-roman">
            <li>
              Subject to the terms and conditions of this Agreement, You grant
              Company a worldwide, non-exclusive, limited term license to
              access, use, process, copy, distribute, perform, export and
              display Client Materials, only as reasonably necessary (a) to
              provide, maintain and improve the Services; (b) to prevent or
              address service, security, support or technical issues; (c) as
              required by law; and (d) as expressly permitted in writing by You.{" "}
            </li>
            <li>
              Client represents and warrants that it has secured all rights in
              and to Client Materials from its customers, users, etc. as may be
              necessary to grant this license.{" "}
            </li>
            <li>
              The Client Materials that you submit may be modified or adapted
              for purposes of transmission, display, or distribution over
              computer networks or any media formats, in order to conform to any
              requirements or limitations in working with such networks,
              services, devices or media. You retain any and all ownership
              rights to the Client Materials that you submit and are responsible
              for protecting those rights. We reserve the right at all times to
              remove or refuse distribution of any Client Materials on or
              through our Services.{" "}
            </li>
          </ol>
        </li>
      </ol>

      <h3>3. Payment</h3>
      <ol className="spaced list-lower-alpha">
        <li className="underline">
          Compensation. Service Provider understands and agrees that the PLS
          User is responsible for payment of every dispatch through the PLS
          Service. You agree and understand that you will not receive payment
          from Company if you, for any reason whatsoever, do not receive payment
          from a PLS User.{" "}
        </li>
        <li className="underline">
          Billing Policies. Certain aspects of the Services may be provided for
          a set fee, monthly or annual subscription, or other charge (“Fees”).
          You agree to the pricing and payment Terms as we may update them from
          time to time. Company may add new services for additional fees and
          charges, or add or amend fees and charges for existing services, at
          any time in its sole discretion. Any change to our pricing or payment
          terms shall become effective in the billing cycle following notice of
          such change to you or at the end of your current subscription.{" "}
        </li>
        <li>
          Please note that any payment terms presented to you in the process of
          using or signing up for Services are deemed part of this Agreement.
          Company uses a third-party payment processor (the “Payment Processor”)
          to bill you through a payment account linked to your Account. The
          processing of payments will be subject to the terms, conditions and
          privacy policies of the Payment Processor in addition to this
          Agreement. We are not responsible for errors by the Payment Processor.
          By utilizing the Services, you agree to pay us, through the Payment
          Processor, in accordance with the applicable payment terms. Company
          reserves the right to change its prices and to offer discounts and
          temporary promotions. You agree that it is your responsibility to
          maintain a valid, non-expired credit card on file with us while
          engaging in fee-based activities on our Services. You agree that if
          you do not maintain a valid, non-expired card on file with us during
          any billing attempt, you may be subject to interest and penalties. You
          irrevocably and expressly authorize Company to withhold any monies
          and/or debit any monies from any account that you have identified to
          Company for any chargebacks, fees, costs, deductions, adjustments and
          any other amounts owed to Company.{" "}
        </li>
        <li>
          Fees are stated exclusive of any taxes, levies, duties, or similar
          governmental assessments of any nature, including, for example,
          value-added, sales, use or withholding taxes, assessable by any
          jurisdiction (collectively, <strong>“Taxes”</strong>). You will be
          responsible for paying all Taxes associated with Your purchases,
          except for those taxes based on Company’s net income.{" "}
        </li>
        <li className="underline">
          California Residents. The provider of services is set forth herein. If
          you are a California resident, in accordance with Cal. Civ. Code
          §1789.3, you may report complaints to the Complaint Assistance Unit of
          the Division of Consumer Services of the California Department of
          Consumer Affairs by contacting them in writing at 1625 North Market
          Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800)
          952-5210 or (916) 445-1254.{" "}
        </li>
      </ol>

      <h3>4. Privacy</h3>
      <p>
        We care about the privacy of our clients. Company’s privacy practices
        are governed by Company’s privacy policy, the most updated copy of which
        can be found at{" "}
        <a href="/privacy-policy/">https://towbook.com/privacy-policy/</a>{" "}
        (&quot;Privacy Policy&quot;). The Privacy Policy does not cover the
        information practices exercised by any third parties that Company does
        not own or control.
      </p>
      <p>
        To request a copy of, or to receive access to, Client Materials or data
        from Company, you must proceed per the terms as specified in the
        Company’s data request policy, the most updated copy of which can be
        found at{" "}
        <a href="/data-request-policy/">
          https://towbook.com/data-request-policy/
        </a>
      </p>

      <h3>5. Security</h3>
      <ol className="spaced list-lower-alpha">
        <li className="underline">
          In order to receive the benefits provided by certain aspects of the
          Services, you may need to grant permission for the software to utilize
          the processor and bandwidth of your computer. You understand that the
          Services will protect the privacy and integrity of your computer
          resources and communication and ensure the unobtrusive utilization of
          your computer resources to the greatest extent possible.
        </li>
        <li className="underline">
          Company cares about and takes very seriously the integrity and
          security of your personal information. We take commercially reasonable
          administrative, physical and electronic measures designed to safeguard
          and protect the Services, Client Materials and content, materials and
          data therein from unauthorized access, use, modification, deletion
          and/or disclosure by our personnel. However, we cannot guarantee that
          unauthorized third parties will never be able to defeat our security
          measures or use your personal information for improper purposes. You
          acknowledge that you provide your personal information at your own
          risk.
        </li>
      </ol>

      <h3>6. Third-Party Links and Information</h3>
      <p>
        The Services may contain links to third-party sites or materials that
        are not owned or controlled by Company. Company does not endorse or
        assume any responsibility for any such third-party sites, information,
        materials, products, or services. If you access a third-party website or
        service from the Services, you do so at your own risk, and you
        understand that this Agreement and Company&apos;s Privacy Policy do not
        apply to your use of such sites. You expressly relieve Company from any
        and all liability arising from your use of any third-party website,
        service, or content. Additionally, your dealings with or participation
        in promotions of advertisers found on the Services, including payment
        and delivery of goods, and any other terms (such as warranties) are
        solely between you and such advertisers. You agree that Company shall
        not be responsible for any loss or damage of any sort relating to your
        dealings with such advertisers.
      </p>

      <h3>7. Representations and Warranties</h3>
      <ol className="spaced list-lower-alpha">
        <li>
          You represent and warrant that: (i) You have the power, right and
          authority to enter into this Agreement, and are capable of forming a
          binding contract: (ii) you will use the Services for lawful purposes
          only and in accordance with this Agreement and all applicable laws,
          regulations and policies, (iii)you own or have sufficient rights in
          and to the Client Materials you submit through the Services to grant
          the licenses set forth in this Agreement with respect to such content
          to Company, that any use by Company of such Client Materials as
          contemplated in this Agreement will not infringe on the rights of any
          third party or violate any applicable laws or regulations, and that
          such content shall not (a) violate any laws or regulations or any
          rights of any third parties, including but not limited to, such
          violations as infringement or misappropriation of any copyright,
          patent, trademark, trade dress, trade secret, music, image or other
          proprietary or property right, false advertising, unfair competition,
          defamation, invasion of privacy or publicity rights, moral or
          otherwise, or rights of celebrity, or any other right of any person or
          entity; (b) contain any material that is unlawful, fraudulent,
          threatening, defamatory, obscene, profane or hateful or (c) contain
          any disabling codes or instructions, or any viruses, worms, Trojan
          horses or other contaminants.{" "}
        </li>
        <li>
          <strong>
            You further represent and warrant that you will not share your
            log-in ID or password or any of the information contained within the
            Company Services with any third party whatsoever without the
            explicit written permission of Company. For the purpose of clarity,
            this includes providing access to, or allowing, third parties to
            log-in through your account, as well as copying and sharing reports
            and/or contact&apos;s emails, names or phone numbers, with any other
            individual, business, marketing or survey company. You agree that
            you alone will be responsible for paying Company and other related
            parties, any damages, losses, penalties and costs whatsoever related
            to a breach by you of this section.{" "}
          </strong>
        </li>
        <li>
          Company represents and warrants that: (i) the Services shall
          materially perform as set forth in any descriptions or specifications
          provided by Company to you, and (ii) the Services will not infringe
          the intellectual property rights of third parties.{" "}
        </li>
      </ol>

      <h3>8. Indemnity</h3>
      <ol className="spaced list-lower-alpha">
        <li className="underline">
          You agree to defend, indemnify and hold harmless Company and its
          subsidiaries, agents, licensors, managers, and other affiliated
          companies, and their employees, contractors, agents, officers and
          directors, from and against any and all claims, damages, obligations,
          losses, liabilities, costs or debt, and expenses (including but not
          limited to attorney’s fees) (“Losses”) arising from: (i) your use of
          and access to the Services, including any Client Materials transmitted
          or received by you; (ii) your violation of any term of this Agreement,
          including without limitation your breach of any of the representations
          and warranties above; (iii) any work performed or services provided by
          you to another User or User’s customer, including, but not limited to
          towing services; (iv) any payment amount owed to another User or any
          other party for services requested or received by you; (v) your
          violation of any third-party right, including without limitation any
          right of privacy or intellectual property rights; (v) your violation
          of any applicable law, rule or regulation; (vi) any Client Materials
          or content that is submitted via your account including without
          limitation misleading, false, or inaccurate information; (vii) your
          negligence or misconduct; or (viii) any other party’s access and use
          of the Services with your unique username, password or other
          appropriate security code.{" "}
        </li>
        <li className="underline">
          Company agrees to defend, indemnify and hold you harmless from third
          party claims arising out of (i) a breach of this Agreement by Company,
          or (ii) claims that the Services infringe the intellectual property
          rights of third parties. If the Services become the subject of an
          intellectual property infringement claim, Company may, at its sole
          option, (a) procure for you a license to continue using the Services
          in accordance with this Agreement; (b) replace or modify the allegedly
          infringing portion of the Services to avoid the infringement, or (iii)
          terminate this Agreement and refund any prepaid fees as applicable to
          the period after termination. This section sets forth your sole remedy
          in the event of any third party infringement claim regarding the
          Services.{" "}
        </li>
      </ol>

      <h3>9. No Warranty</h3>
      <ol className="spaced list-lower-alpha">
        <li className="underline">
          THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF
          THE SERVICES IS AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR ANY
          AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON THE SERVICES OR
          THE INFORMATION CONTAINED THEREIN, INCLUDING INACCURATE OR INCOMPLETE
          INFORMATION. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE
          SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT. COMPANY, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS
          LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR
          CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE
          SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
          UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED;
          OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
          ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
          SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR
          LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF THE
          SERVICE.{" "}
        </li>
        <li className="underline">
          COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME LIABILITY OR
          RESPONSIBILITY FOR ANY TRANSACTIONS, TOWS, WORK PERFORMED, ACTS OR
          OMISSIONS, BY USERS OR ANY OTHER PARTY, AND COMPANY WILL NOT BE A
          PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN USERS AND/OR
          USER’S CUSTOMERS. FURTHERMORE AND WITHOUT LIMITATION, COMPANY DOES NOT
          WARRANT THAT THE USE OF THE SERVICES WILL RESULT IN ANY PARTICULAR
          RESULTS. COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
          RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
          THIRD PARTY THROUGH THE SERVICES OR ANY HYPERLINKED WEBSITE OR
          SERVICE, AND COMPANY WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY
          TRANSACTION BETWEEN YOU AND PROVIDERS OF SUCH THIRD PARTY PRODUCTS OR
          SERVICES.{" "}
        </li>
        <li className="underline">
          COMPANY MAY ALTER, SUSPEND, ADD TO, OR DISCONTINUE THE SERVICES IN
          WHOLE OR IN PART AT ANY TIME FOR ANY REASON, WITHOUT NOTICE OR COST.
          COMPANY ASSUMES NO RESPONSIBILITY FOR YOUR ABILITY TO (OR ANY COSTS OR
          FEES ASSOCIATED WITH YOUR ABILITY TO) OBTAIN ACCESS TO THE SERVICES.
          COMPANY DOES NOT ASSUME ANY LIABILITY FOR THE FAILURE TO STORE OR
          MAINTAIN ANY CLIENT MATERIALS, COMMUNICATIONS, ACCOUNT INFORMATION, OR
          PERSONAL SETTINGS. BY HAVING ACCESS TO THE SERVICES, YOU AGREE THAT
          COMPANY AND PARTNERS MAY PLACE ADVERTISEMENTS ON THE SERVICES. THE
          TYPES OF ADVERTISEMENTS ARE SUBJECT TO CHANGE. THE SERVICES MAY BECOME
          UNAVAILABLE DUE TO MAINTENANCE OR MALFUNCTION OF COMPUTER EQUIPMENT,
          SERVERS, OR OTHER REASONS.{" "}
        </li>
        <li className="underline">
          YOU AGREE THAT COMPANY HAS MADE NO AGREEMENTS, REPRESENTATIONS OR
          WARRANTIES OTHER THAN THOSE EXPRESSLY SET FORTH IN THIS AGREEMENT, AND
          THAT NO FUTURE AGREEMENT, REPRESENTATION OR WARRANTY OF COMPANY WITH
          REGARD TO SERVICES PROVIDED UNDER THIS AGREEMENT SHALL BE EFFECTIVE
          UNLESS EXPRESSLY STATED IN AN AMENDMENT TO THIS AGREEMENT SIGNED BY
          BOTH PARTIES.{" "}
        </li>
        <li className="underline">
          THE DISCLAIMERS AND EXCLUSIONS UNDER THIS AGREEMENT WILL NOT APPLY TO
          THE EXTENT PROHIBITED BY APPLICABLE LAW.{" "}
        </li>
      </ol>

      <h3>10. Limitation of Liability</h3>
      <ol className="spaced list-lower-alpha">
        <li>
          IF YOU ARE DISSATISFIED WITH THE SERVICES, OR ANY MATERIALS, OR
          PRODUCTS THEREIN, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
          USING THE SERVICES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
          IN NO EVENT SHALL COMPANY, ITS AFFILIATES, AGENTS, DIRECTORS OR
          EMPLOYEES, BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION
          DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
          LOSSES, WHETHER AS CLAIM FOR ANY SUCH LIABILITY OR DAMAGES IS PREMISED
          UPON BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT
          LIABILITY, OR ANY OTHER THEORY OF LIABILITY, EVEN IF COMPANY HAS BEEN
          APPRISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES OCCURRING.
        </li>
        <li>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY ASSUMES NO
          LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF
          ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR
          SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
          AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (V)
          ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED
          TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR
          OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A
          RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
          OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR (VII) THE
          DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY. YOU
          ACKNOWLEDGE AND AGREE THAT THE FEES AND OTHER CHARGES WHICH COMPANY IS
          CHARGING UNDER THIS AGREEMENT DO NOT INCLUDE ANY CONSIDERATION FOR
          ASSUMPTION BY COMPANY OF THE RISK OF YOUR INDIRECT, CONSEQUENTIAL OR
          INCIDENTAL DAMAGES OR OF UNLIMITED DIRECT DAMAGES. IN NO EVENT SHALL
          COMPANY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR
          LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES,
          OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE
          AMOUNT YOU PAID TO COMPANY HEREUNDER OR $100.00, WHICHEVER IS GREATER.{" "}
        </li>
        <li>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR
          EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC
          LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY DEPENDING
          ON THE APPLICABLE JURISDICTION. THE DISCLAIMERS, EXCLUSIONS, AND
          LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL NOT APPLY TO THE
          EXTENT PROHIBITED BY APPLICABLE LAW.{" "}
        </li>
        <li>
          Unless otherwise explicitly stated, the Services are controlled and
          operated from facilities in the United States. Those who access or use
          the Services from other jurisdictions do so at their own volition and
          are entirely responsible for compliance with all applicable United
          States and local laws and regulations, including but not limited to
          export and import regulations. You may not use the Services if you are
          a resident of a country embargoed by the United States, or are a
          foreign person or entity blocked or denied by the United States
          government.
        </li>
      </ol>

      <h3>11. Non-Compete and Business Interference</h3>
      <ol className="spaced list-lower-alpha">
        <li>
          You recognize Company has proprietary relationships with its
          employees, consultants, referral sources, service providers, vendors,
          clients and contractors (the “Protected Parties”). During the term of
          this Agreement and for a period of twenty-four (24) months thereafter,
          whether under this Agreement, or otherwise (the “Restricted Period”),
          you hereby agree on behalf of yourself, and any related parties,
          entities or subsidiaries, that you will not, without the prior written
          approval of Company, directly or indirectly:
          <ol className="spaced list-lower-roman">
            <li>
              induce, advise or counsel any Protected Parties to terminate,
              reduce or modify their relationship with Company,{" "}
            </li>
            <li>
              solicit, recruit, hire, or engage any Protected Parties, or{" "}
            </li>
            <li>
              otherwise interfere with or damage, or attempt to interfere with
              or damage, the relationship between Company and any Protected
              Parties.{" "}
            </li>
          </ol>
        </li>
        <li>
          Recognizing Company’s legitimate interest in protecting its
          Confidential Information and Company Content, including the Services
          (and information contained therein), and its business, developed by
          Company at substantial time and expense, you covenant and agrees that,
          during the Restricted Period, you shall not, either directly or
          indirectly, alone or in partnership, as a service provider, business,
          employee, consultant, owner, independent contractor or otherwise,
          engage in developing product functionality and/or provide services
          that competes with any of the features or functions of the Services.{" "}
        </li>
        <li>
          You agree that, in addition to any other remedies that may be
          available, without the necessity of posting bond or proving actual
          damages, Company shall be entitled to seek injunctive relief against
          the threatened breach of this Section or the continuation of any such
          breach by you, without limiting any other rights and remedies to which
          the Company may be entitled to at law, in equity or under this
          Agreement.{" "}
        </li>
      </ol>

      <h3>
        12. Governing Law, Arbitration, and Class Action/Jury Trial Waiver
      </h3>
      <ol className="spaced list-lower-alpha">
        <li className="underline">
          Governing Law. This Agreement shall be governed by and construed and
          enforced in accordance with the laws of the United States and the
          State of Michigan as it applies to a contract made and to be performed
          in such state. You consent and agree that the state and federal courts
          of St. Clair County, Michigan shall have personal jurisdiction over
          you, as well as subject matter jurisdiction with respect to any
          provision of this Agreement, and shall be the exclusive forums for any
          litigation arising out of or relating to this Agreement, subject to
          section b below. You also agrees to and hereby waive your rights to a
          trial by jury and agrees to accept service of process by mail.{" "}
        </li>
        <li className="underline">
          Arbitration. READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE
          PARTIES TO ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU
          CAN SEEK RELIEF FROM COMPANY. For any dispute with Company, you agree
          to first contact us at{" "}
          <a href="mailto:support@towbook.com">support@towbook.com</a> and
          attempt to resolve the dispute with us informally. In the unlikely
          event that Company has not been able to resolve a dispute it has with
          you after sixty (60) days, we each agree to resolve any claim,
          dispute, or controversy (excluding any claims for injunctive or other
          equitable relief as provided below) arising out of or in connection
          with or relating to this Agreement, or the breach or alleged breach
          thereof (collectively, “Claims”), by binding arbitration by JAMS,
          under the Optional Expedited Arbitration Procedures then in effect for
          JAMS, except as provided herein. JAMS may be contacted at
          www.jamsadr.com. The arbitration will be conducted in St. Clair
          County, Michigan, unless you and Company agree otherwise. If you are
          using the Services for commercial purposes, each party will be
          responsible for paying any JAMS filing, administrative and arbitrator
          fees in accordance with JAMS rules, and the award rendered by the
          arbitrator shall include costs of arbitration, reasonable attorneys’
          fees and reasonable costs for expert and other witnesses. If you are
          an individual using the Services for non-commercial purposes: (i) JAMS
          may require you to pay a fee for the initiation of your case, unless
          you apply for and successfully obtain a fee waiver from JAMS; (ii) the
          award rendered by the arbitrator may include your costs of
          arbitration, your reasonable attorney’s fees, and your reasonable
          costs for expert and other witnesses; and (iii) you may sue in a small
          claims court of competent jurisdiction without first engaging in
          arbitration, but this does not absolve you of your commitment to
          engage in the informal dispute resolution process. Any judgment on the
          award rendered by the arbitrator may be entered in any court of
          competent jurisdiction. Nothing in this Section shall be deemed as
          preventing Company from seeking injunctive or other equitable relief
          from the courts as necessary to prevent the actual or threatened
          infringement, misappropriation, or violation of our data security,
          intellectual property rights or other proprietary rights.{" "}
        </li>
        <li className="underline">
          Class Action/Jury Trial Waiver. WITH RESPECT TO ALL PERSONS AND
          ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED OR USED THE SERVICE
          FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT
          IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE
          ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS
          WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE,
          THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU
          AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND COMPANY ARE EACH
          WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
          ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER
          REPRESENTATIVE PROCEEDING OF ANY KIND.{" "}
        </li>
        <li>
          Expenses and Attorneys’ Fees. In the event any action is brought to
          enforce any provision of the Agreement or to declare a breach of the
          Agreement, the prevailing party shall be entitled to recover, in
          addition to any other amounts awarded, reasonable legal and other
          related costs and expenses, including attorney’s fees, incurred
          thereby.{" "}
        </li>
      </ol>

      <h3>13. Termination</h3>
      <ol className="spaced list-lower-alpha">
        <li>
          Either party may terminate the Agreement upon written notice to the
          other party if the other party materially breaches the Agreement and
          such breach is not cured within thirty (30) days after the
          non-breaching party provides notice of the breach.{" "}
        </li>
        <li>
          We have the right to deny access to, and to suspend or terminate your
          access to, the Services, or to any features or portions of such, and
          to remove and discard any Client Materials you have submitted, at any
          time and for any reason, including for any violation by you of this
          Agreement. In the event that we suspend or terminate your access to
          and/or use of the Services, you will continue to be bound by the Terms
          that were in effect as of the date of your suspension or termination.{" "}
        </li>
      </ol>

      <h3>14. Confidential Information </h3>
      <ol className="spaced list-lower-alpha">
        <li>
          Each party (<strong>“Disclosing Party”</strong>) may disclose{" "}
          <strong>“Confidential Information”</strong> to the other party (
          <strong>“Receiving Party”</strong>) in connection with this Agreement,
          which is anything that reasonably should be understood to be
          confidential given the nature of the information and the circumstances
          of disclosure. Notwithstanding the above, Confidential Information
          does not include information that (a) is or becomes generally
          available to the public without breach of any obligation owed to the
          Disclosing Party; (b) was known to the Receiving Party prior to its
          disclosure by the Disclosing Party without breach of any obligation
          owed to the Disclosing Party; (c) is received from a third party
          without breach of any obligation owed to the Disclosing Party; or (d)
          was independently developed by the Receiving Party.{" "}
        </li>
        <li>
          The Receiving Party will (a) take at least reasonable measures to
          prevent the unauthorized disclosure or use of Confidential
          Information, and limit access to those employees, affiliates and
          contractors who need to know such information in connection with this
          Agreement; and (b) not use or disclose any Confidential Information of
          the Disclosing Party for any purpose outside the scope of this
          Agreement.{" "}
        </li>
        <li>
          The Receiving Party may access or disclose Confidential Information of
          the Disclosing Party if it is required by law; provided, however, that
          the Receiving Party gives the Disclosing Party prior notice of the
          compelled access or disclosure (to the extent legally permitted) and
          reasonable assistance, at the Disclosing Party&apos;s cost, if the
          Disclosing Party wishes to contest the access or disclosure.{" "}
        </li>
      </ol>

      <h3>15. DMCA Notice </h3>
      <ol className="spaced list-lower-alpha">
        <li>
          We respect the intellectual property rights of others, and we prohibit
          the uploading, posting, transmitting, sharing, or otherwise making
          available any material that violates another party&apos;s intellectual
          property rights. When we receive proper notification of alleged
          copyright infringement, we may promptly remove or disable access to
          any allegedly infringing material and may terminate any Account of any
          repeat infringer, in accordance with the Digital Millennium Copyright
          Act (&quot;DMCA&quot;). If you believe that your own copyrighted work
          is accessible on the Services or any service in violation of your
          copyright, then{" "}
        </li>
        <li>
          You may provide our designated agent with a written communication as
          set forth in the DMCA, 17 U.S.C. Sec. 512(c)(3) that contains
          substantially the following information:
          <ul className="spaced">
            <li>
              Identify in sufficient detail the copyrighted work or intellectual
              property that you claim has been infringed, so that we can locate
              the material.{" "}
            </li>
            <li>
              Provide the electronic or physical signature of the owner of the
              copyright or a person authorized to act on the owner&apos;s
              behalf.{" "}
            </li>
            <li>
              Include a statement by you that you have a good faith belief that
              the disputed use is not authorized by the copyright owner, its
              agent, or the law.{" "}
            </li>
            <li>
              Include a statement by you that the information contained in your
              notice is accurate and that you attest under the penalty of
              perjury that you are the copyright owner or that you are
              authorized to act on the copyright owner&apos;s behalf.{" "}
            </li>
          </ul>
        </li>
        <li>
          Include your name, mailing address, telephone number, and email
          address. You may submit your notification of Alleged Copyright
          Infringement by sending a letter to our designated agent by mail to:
          <p className="underline">
            <br />
            Extric LLC
            <br />
            Attn: Records Custodian <br />
            c/o Registered Agent
            <br />
            CSC-Lawyers Incorporating Service
            <br />
            601 Abbott Road
            <br />
            East Lansing, MI 48823
          </p>
        </li>
      </ol>

      <h3>16. General</h3>
      <ol className="spaced list-lower-alpha">
        <li>
          Independent Contractor. The parties to the Agreement are independent
          contractors and nothing in the Agreement shall be deemed to make
          either party an agent, employee, or partner of the other party.
          Neither party shall have any authority to bind, commit, or otherwise
          obligate the other party in any manner whatsoever.{" "}
        </li>
        <li className="underline">
          International Users. Unless otherwise explicitly stated, we control
          and operate the Services from our facilities in the United States of
          America. If you choose to access the Services from other locations,
          you are responsible for compliance with local laws, if and to the
          extent local laws are applicable. Information collected through the
          Services may be stored and processed in the United States or, if and
          as applicable for international Users, any other country in which
          Towbook or its affiliates, subsidiaries or service providers maintain
          facilities. If your information is transferred to a country other than
          your home country, we will take measures to protect it with
          appropriate contract clauses or other applicable safeguards. If you
          are an international User, you acknowledge that by using the Services,
          you are: (a) permitting the transfer of your information to the United
          States which may not have the same data protection laws as the country
          in which you reside; and (b) permitting the use of your information in
          accordance with our Privacy Policy.
        </li>
        <li className="underline">
          Assignment. This Agreement, and any rights and licenses granted
          hereunder, may not be transferred or assigned by you, but may be
          assigned by Company without restriction. Any attempted transfer or
          assignment in violation hereof shall be null and void.{" "}
        </li>
        <li className="underline">
          Notification Procedures and Changes to the Agreement. Company may
          provide notifications, whether such notifications are required by law
          or are for marketing or other business related purposes, to you via
          email notice, written or hard copy notice, or through posting of such
          notice on our website, as determined by Company in our sole
          discretion. Company reserves the right to determine the form and means
          of providing notifications to you, provided that you may opt out of
          certain means of notification as described in this Agreement. Company
          is not responsible for any automatic filtering you or your network
          provider may apply to email notifications we send to the email address
          you provide us. Company may, in its sole discretion, modify or update
          this Agreement from time to time, and so you should review this page
          periodically. When we change the Agreement in a material manner, we
          will update the last modified date at the bottom of this page. Your
          continued use of the Services after any such change constitutes your
          acceptance of the new Agreement. If you do not agree to any of these
          terms or any future terms, do not use or access (or continue to
          access) the Services.{" "}
        </li>
        <li className="underline">
          Electronic Communications. Our communications with you via the
          Services use electronic means, whether you visit the Services or send
          us an email, or whether we post notices on the Services or communicate
          with you via email or text. For contractual purposes, you consent to
          receive communications from us in an electronic form, and you agree
          that all terms and conditions, agreements, notices, disclosures, and
          other communications that we provide to you electronically satisfy any
          legal requirement that such communications would satisfy if it were in
          writing.{" "}
        </li>
        <li className="underline">
          Entire Agreement / Severability. This Agreement, together with any
          amendments and any additional agreements you may enter into with
          Company in connection with the Services, shall constitute the entire
          agreement between you and Company concerning the Services. If any
          provision of this Agreement is deemed invalid by a court of competent
          jurisdiction, the invalidity of such provision shall not affect the
          validity of the remaining provisions of this Agreement, which shall
          remain in full force and effect, except that in the event of
          unenforceability of the universal Class Action/Jury Trial Waiver, the
          entire arbitration agreement shall be unenforceable.{" "}
        </li>
        <li className="underline">
          No Waiver. No waiver of any term of this Agreement shall be deemed a
          further or continuing waiver of such term or any other term, and
          Company’s failure to assert any right or provision under this
          Agreement shall not constitute a waiver of such right or provision.{" "}
        </li>
        <li className="underline">
          Feedback. We may use any reports, comments, ideas and suggestions in
          any form regarding the Services that you provide to us (collectively,
          the “Feedback”). You grant us a worldwide, non-exclusive, irrevocable,
          perpetual, royalty-free right and license to incorporate and use the
          Feedback in connection with any products and services.{" "}
        </li>
        <li>
          Interpretation. Section headings are for reference only, and shall not
          be construed as substantive parts of this Agreement. Each capitalized
          term used in this Agreement (including any schedule or exhibit of this
          Agreement) shall have the meaning attributed to it in any part of this
          Agreement (including any such schedules or exhibits).{" "}
        </li>
        <li>
          Survival. You acknowledge and agree that all indemnification,
          confidentiality, intellectual property, governing law, dispute
          resolution, enforceability/injunctive relief, limitation of liability
          and other clauses including those provisions which by their terms
          contemplate survival shall survive the termination or expiration of
          this Agreement regardless of the cause of such termination.{" "}
        </li>
        <li>
          Force Majeure. Neither party shall be liable to the other party for
          any delay or failure of said party to perform its obligations
          hereunder (except for payment obligations) if such delay or failure
          arises from any cause or causes beyond the reasonable control of such
          party. Such causes shall include, but are not limited to, acts of God,
          floods, fires, loss of electricity or other utilities, or delays by
          either party in providing required resources or support or performing
          any other requirements hereunder.{" "}
        </li>
      </ol>

      <div className="block px-4 py-3 my-8 text-sm text-center text-gray-100 bg-gray-600 rounded-lg sm:text-base font-500">
        This Terms of Service Agreement was last updated on July 1, 2023
      </div>

      <h2>PROVIDER LOCATOR SERVICE ADDENDUM TO TERMS OF SERVICE</h2>
      <p>
        Thanks for using Towbook! This addendum (“Addendum”) to the Terms of
        Service and End User License Agreement (“Agreement”) explains the terms
        by which you (“Service Provider”) may use and/or be included in our
        Provider Locator Service (“PLS”)/Out of Network (“OON”). To the extent
        of any inconsistency or conflict between this Addendum and the
        Agreement, the terms of this Addendum shall govern. Capitalized terms
        not defined herein shall have the meaning set forth in the Agreement.
      </p>

      <ol className="spaced list-upper-roman">
        <li>
          Services.
          <ol className="spaced list-lower-alpha">
            <li>
              Service Providers that enroll in the PLS can elect to opt-out of
              the PLS if they no longer wish to receive “job postings.” They can
              either opt-out from receiving all PLS postings from any accounts,
              or just from receiving PLS postings from specific accounts.
            </li>
            <li>
              Company’s PLS provides motor clubs, insurance companies and other
              roadside assistance companies (“PLS Users“) with the ability to
              search and locate Service Providers, on behalf of their customers,
              in locations out of the PLS Users’ general network or location
              area.
            </li>
            <li>
              Service Provider hereby grants to Company a license to use Service
              Provider’s name and logo (“Marks”) solely in connection with
              Company’s provision of the PLS pursuant to this Addendum. Company
              acknowledges and agrees that you own the Marks and that any and
              all goodwill and other proprietary rights that are created by or
              that result from Company’s use of the Marks hereunder inure solely
              to your benefit.
            </li>
            <li>
              Service Provider also grants Company the right to provide PLS
              Users with its general business information, including but not
              limited to your name, address, telephone and email address. In
              addition, when Service Providers accept a dispatch from an PLS
              User, Service Provider is agreeing to share any and all additional
              information from the dispatch including, but not limited to, gps
              data, geocode information, photos, signatures, signature data,
              damage reports and other related data as requested or required
              with the PLS User and the PLS User’s customers (“Additional
              Information”). Service Provider understands and agrees that the
              PLS User may withhold payment to Service Provider if Service
              Provider not provide the any of the required Additional
              Information.”
            </li>
            <li>
              PLS Users will, in their sole and absolute discretion, be
              responsible for selecting or not selecting a particular Service
              Provider though the PLS. PLS Users will also be solely responsible
              and liable for all applicable payments that become due to Service
              Provider as a result of dispatches through the PLS.
            </li>
            <li>
              Once Service Provider accepts a dispatch, Service Provider is not
              permitted to change the terms of the dispatch, including but not
              limited to the fees for services, without explicit permission from
              the PLS User.
            </li>
            <li>
              Service Provider may not attempt to upsell or offer other services
              to PLS User’s customers without explicit permission from the PLS
              User.
            </li>
          </ol>
        </li>
        <li>
          Compensation. Service Provider understands and agrees that the PLS
          User is responsible for payment of every dispatch through the PLS. You
          agree and understand that you will not receive payment from Company if
          you, for any reason whatsoever, do not receive payment from a PLS
          User. In addition, Service Provider understands and agrees that PLS
          Users may withhold payments to Service Provider if Service Provider
          does not fulfill ALL of the requirements of the dispatch, including
          providing ALL of the Additional Information, such as, for example,
          providing photographs, updating statuses, and having GPS on.
        </li>
      </ol>

      <p>
        Except as amended hereby, the Agreement shall remain in full force and
        effect in accordance with its terms.
      </p>

      <div className="block px-4 py-3 my-8 text-sm text-center text-gray-100 bg-gray-600 rounded-lg sm:text-base font-500">
        This Addendum to the Terms of Service Agreement was last updated on July
        1, 2023
      </div>
    </ContentBlock>
  </Layout>
)

export default PageTermsOfService
